table {
	border-collapse: separate;
	border-spacing: 0;
	margin: 0 0 $vert-space;
	width: auto;
	display: block;
	
	caption {
		background:$table-caption-bg-color;
		border-bottom:$table-caption-border;
		color::$table-caption-text-color;
		font: $table-caption-font;
		padding:$vert-space/3 $vert-space/2;
	}

	th {
		background:$table-header-bg-color;
		border-bottom: $table-header-border;
		font-weight: 400;
		//padding: $vert-space/4 0;
		font-size: 1.333rem;
		text-align: left;
		word-wrap: break-word;
		border-left:$table-header-cell-border;
	}

	td {
		border-bottom: $primary-border;
		line-height: $base-line-height;
		padding: $table-padding;
		border-left:$table-header-cell-border;
		word-wrap: break-word;
	}

	thead {
		th {
			background-color: $table-header-bg-color;
			padding: $table-padding;
		}
	}
	
	tr,
	td,
	th {
		vertical-align: middle;
	}
	
	thead {
		tr th:first-child {
			border-left: none;
		}
	}

	tbody {
		background-color: $table-bg-color;
		border: none;

		tr:hover > td, tr:hover > th {
			background-color: $table-hover-bg-color;
		}
		tr td:first-child {
			border-left: $table-border;
		}
		tr td:last-child {
			border-right: $table-border;
		}
		
		td {
			border-top: 0px;
			border-bottom: $table-border;
			padding: $table-padding;

			button {
				display: inline-block;
				font-size: .7em;
				line-height: $base-line-height;
				margin-bottom: .3em;
				margin-right: 0.5em;
				outline: none;
				padding: .3em 1em;
				width: 100%;

				@include media($narrow) {
					margin-bottom: 0;
					width: auto;
				}
			}
		}
	}
}

.palette-alt-2 {
	table {
		thead {
			th {
				background-color: $table-alt-1-header-bg-color;
			}
		}
		
		tbody {
			tr td:first-child {
				border-left: none;
			}
			tr td:last-child {
				border-right: none;
			}
			tr:last-child td {
				border-bottom: none;
			}
		}
	}
}

.palette-alt-3 {
	caption {
		border-bottom:$table-alt-2-caption-border;
	}

	th {
		border-bottom: $table-alt-2-header-border;
		border-left:$table-alt-2-header-cell-border;
	}

	td {
		border-left:$table-alt-2-header-cell-border;
	}
	
	thead {
		th {
			background-color: $table-alt-2-header-bg-color;
		}
	}

	tbody {
		tr td:first-child {
			border-left: none;
		}
		tr td:last-child {
			border-right: none;
		}
		tr:last-child td {
			border-bottom: none;
		}
	}
}
