// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary           : #4A4E56; /* $davys_grey */ 
$secondary         : #6D7587; /* $aurometalsaurus */


// /* MAIN PALETTE */ //
$gunmental          : #2B2D42;
$davys_grey         : #4A4E56;
$aurometalsaurus    : #6D7587;


// /* SEC PALETTE */ //
$pale_grey           : #f5f8fa; /* pale_grey */

$black               : #000;
$grey                : #666;
$white               : #FFF;

$mustard             : #FFC857;
$rusty_red           : #DB324D;
$caput_mortuum       : #5B2333;
$cornsilk            : #FFF4DE;

$dark_cyan           : #008191;
$maximum_blue_green  : #31B3C4;
$azureish_white      : #DAF0F2;
$alice_blue          : #EEF8FA;


// MENU

// FILES
$dark_spring_green 		: #207347; /* xlsx */
$bdazzled_blue 				: #2D5994; /* docx */
$lemon_curry 					: #D19B15; /* jpg */
$internationl_orange 	: #C11E07; /* pdf */
$dark_pastel_red 			: #D04727; /* pptx */
$dark_imperial_blue 	: #023A6D; /* txt */
//$black 							: #000000; /* zip */

$xlsx_color	 					: $dark_spring_green; /* xlsx */
$docx_color	 					: $bdazzled_blue; /* docx */
$jpg_color	 					: $lemon_curry; /* jpg */
$pdf_color	 					: $internationl_orange; /* pdf */
$pptx_color	 					: $dark_pastel_red; /* pptx */
$txt_color	 					: $dark_imperial_blue; /* txt */
$zip_color	 					: black; /* zip */


// /* LINKS */ //
$action-default      : $dark_cyan;
$action-hover        : $rusty_red;
$action-active       : $rusty_red; 
$action-active-hover : $rusty_red; 

$action-alt              : $davys_grey;
$action-alt-hover        : white;
$action-alt-active       : white; 
$action-alt-active-hover : white; 

$action-alt-2              : $mustard;
$action-alt-2-hover        : white;
$action-alt-2-active       : white; 
$action-alt-2-active-hover : white; 


// /* SHADOWS */ //
$box-shadow : 0px 1px 6px rgba(0, 0, 0, 0.1);
$top-date-shadow : 0 -1px 2px rgba(0,0,0,0.1);


// /* BORDERS */ //
$primary-border-color          : rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                :  1px solid $primary-border-color;

$secondary-border-color        : $aurometalsaurus;
$secondary-border-color-radius : 0;
$secondary-border              : 1px solid $secondary-border-color;

$tertiary-border-color         : #F3F3F3;
$tertiary-border-radius        : 0;
$tertiary-border               : 1px solid $tertiary-border-color;

$base-border                   : 1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 3px ;


// /* BORDER BUTTONS */ //
$feed-link-default-line	  	    : radial-gradient($rusty_red,transparent 75%);
$feed-link-default-hover-line	  : radial-gradient($dark_cyan,transparent 75%);

$feed-link-alt-line	  	        : radial-gradient(white,transparent 75%);
$feed-link-alt-hover-line	      : radial-gradient($dark_cyan,transparent 75%);


// /* TABLES */ //

// $table-stripe-bg          : darken($table-bg-color, 4);
// $table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : .5rem 1rem;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;

$table-border             : 1px solid $azureish-white;
$table-bg-color           : white;
$table-hover-bg-color     : lighten($azureish-white, 10%);
$table-cell-border        : 1px solid $azureish_white;
$table-cell-text          : $primary;
$table-caption-bg-color   : $azureish-white;
$table-caption-text-color : $primary;
$table-caption-border     : 1px solid $azureish_white;
$table-header-bg-color    : $azureish_white;
$table-header-border      : 2px solid $dark_cyan;
$table-header-cell-border : 1px solid $dark_cyan;
$table-header-text        : $gunmental;

$table-alt-1-header-bg-color    : $alice_blue;

$table-alt-2-border             : 1px solid $secondary;
$table-alt-2-cell-border        : 1px solid $secondary;
$table-alt-2-caption-border     : 1px solid $secondary;
$table-alt-2-header-bg-color    : $mustard;
$table-alt-2-header-border      : 1px solid $secondary;
$table-alt-2-header-cell-border : 1px solid $secondary;





// /* FORMS  */ //
$fieldset-border-color : transparent;
$form-box-shadow       : none;
$form-box-shadow-focus : none; 
$form-border-color     : $secondary-border-color;
$form-border           : none; //1px solid $secondary-border-color;
$form-border-radius    : 30px;

$exposed-form-bg       : $azureish_white;
$exposed-form-field-bg : white;
$exposed-form-alt-bg       : white;
$exposed-form-alt-field-bg : $alice_blue;

$button-border         : 1px solid $action-active;
$button-border-radius  : 3px;

$button-border-color         : $action-active;
$button-bg                   : transparent;
$button-text                 : $action-active;
$button-hover-border-color   : $action-default;
$button-hover-bg             : transparent;
$button-hover-text           : $action-default;

$button-alt-border-color         : $caput_mortuum;
$button-alt-bg                   : transparent;
$button-alt-text                 : $caput_mortuum;
$button-alt-hover-border-color   : $action-default;
$button-alt-hover-bg             : transparent;
$button-alt-hover-text           : $action-default;

// $button-form-submit-bg      : $action-hover;
// $button-form-reset-bg       : $dark-imperial-blue;
// $button-form-hover-bg       : $action-default;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $pale_grey !default;