// /* SIDEBAR Container styles */

$sidebar-second-bg       : none;
$sidebar-second-border   : none;
$sidebar-second-margin   : 0;
$sidebar-second-padding  : 0;

$side-row-title-font 			: 1.889rem/1.3 $title-font-family; // /* Sidebar nugget title */

$side-row-bg                   	: $alice_blue;
$side-row-border               	: none; //1px solid transparent transparent blue transparent;
$side-row-border-radius        	: 0;
$side-row-box-shadow 						: none;
$side-row-padding              	: $vert-space $horz-space;
$side-row-inner-mobile-padding 	: 0;
$side-row-inner-padding        	: 0;
$side-row-margin               	: 0 0 $vert-space*1.25;

/* SIDEBAR CARDS */
@mixin palette-sidebar-cards {
	@include card-light();
}
@mixin palette-sidebar-card-more-link {
	@include card-more-link-default();
}
@mixin palette-sidebar-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-sidebar-button-link {
	@include button-link-default();
}
@mixin palette-sidebar-slick-controls {
	@include slick-controls-alt-1();
}

$card-sidebar-border        : none;
$card-sidebar-border-radius : 3px;
$card-sidebar-shadow        : $box-shadow;
$card-sidebar-text-padding  : $vert-space $horz-space !default;
$card-sidebar-margin-bottom : $vert-space !default;