/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;

	.card-qa-answer {
		display: none;
		padding: 10px;	
		box-sizing: border-box;
 
		:last-child {
			margin-bottom: 0;
		}
	}
	
	.card-qa-question {
		text-decoration: none;
		color: $action-default;
		padding: 10px 0 10px 30px;
		line-height: 20px;
		display: block;
		/*border: 1px solid #d0d0d0;*/
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		background: $ico-expand no-repeat scroll 0 12px / 16px 16px transparent;
		
		p {
			margin-bottom: 0;
		}
		
		&:hover {
			color: $action-hover;
			background: $ico-expand-over no-repeat scroll 0 12px / 16px 16px transparent;
		}

		&.active {
			//font-weight: bold;
			background: $ico-collapse no-repeat scroll 0 12px / 16px 16px transparent;
			
			&:hover {
				background: $ico-collapse-over no-repeat scroll 0 12px / 16px 16px transparent;
			}
		}
	}
}

.palette-highlight .view-faqs {
	.card-qa-question {
		color: $gunmental;
		background: $ico-alt-expand no-repeat scroll 0 12px / 16px 16px transparent;

		&:hover {
			color: white;
			background: $ico-alt-expand-over no-repeat scroll 0 12px / 16px 16px transparent;
		}
		
		&.active {
			background: $ico-alt-collapse no-repeat scroll 0 12px / 16px 16px transparent;
			
			&:hover {
				background: $ico-alt-collapse-over no-repeat scroll 0 12px / 16px 16px transparent;
			}
		}
	}
}