.item-list  ul.pager {
	//border-top: $primary-border;
	position: relative;
	display: flex;
	justify-content: center;
	
	@media screen and (max-width: $narrow) {
		font-size: 0.750em;
	}
	a {
		padding:0.25em 0.5em;
		
		&:hover {
			color:$mustard;
		}
	}
	
	li.pager-next,
	li.pager-last,
	li.pager-previous,
	li.pager-first {
		width: 48px;
		height: 48px;
		padding: 0;
		margin: 0 5px;
		
		a {
			display: block;
			background: transparent $pager-green-previous no-repeat scroll 50% 50% / 48px 48px;
			width: 48px;
			height: 48px;
			text-indent: -9999px;
		}
	}
	
	// li.pager-item.pager-next,
	li.pager-next {
		margin-left: $horz-space;
		a {
			background-image: $pager-green-next;
			
			&:hover {
				background-image: $pager-yellow-next;
			}
		}
	}
	// li.pager-item.pager-last,
	li.pager-last  {
		a {
			background-image: $pager-green-last;
			
			&:hover {
				background-image: $pager-yellow-last;
			}
		}
	}
	// li.pager-item.pager-previous,
	li.pager-previous {
		margin-right: $horz-space;
		a {
			background-image: $pager-green-previous;
			
			&:hover {
				background-image: $pager-yellow-previous;
			}
		}
	}
	// li.pager-item.pager-first,
	li.pager-first {
		a {
			background-image: $pager-green-first;
			
			&:hover {
				background-image: $pager-yellow-first;
			}
		}
	}
	li,
	li.pager-item {
		display: inline-block;
		margin: 0;
		font-size: 1.222rem;
		font: $title-font-family;
		font-weight: normal;
		color: $aurometalsaurus;
		
		a {
			color:$aurometalsaurus;
			
			&:hover {
				color:$mustard;
			}
		}
	}
	li.pager-current {
		color:$rusty_red;
	}
}

.palette-highlight {
	.item-list  ul.pager {
		// li.pager-item.pager-next,
		li.pager-next {
			a {
				background-image: $pager-white-next;
				
				&:hover {
					background-image: $pager-yellow-next;
				}
			}
		}
		// li.pager-item.pager-last,
		li.pager-last  {
			a {
				background-image: $pager-white-last;
				
				&:hover {
					background-image: $pager-yellow-last;
				}
			}
		}
		// li.pager-item.pager-previous,
		li.pager-previous {
			a {
				background-image: $pager-white-previous;
				
				&:hover {
					background-image: $pager-yellow-previous;
				}
			}
		}
		// li.pager-item.pager-first,
		li.pager-first {
			a {
				background-image: $pager-white-first;
				
				&:hover {
					background-image: $pager-yellow-first;
				}
			}
		}
		li,
		li.pager-item {
		
		a {
			color:white;
			
			&:hover {
				color:$mustard;
			}
		}
		}
		li.pager-current {
			color:$primary;
		}
	}
}