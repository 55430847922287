.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	.views-row {
		padding: $vert-space/2 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;
		
		.file-icon {
			float:left;
			margin:0 $horz-space 0 0;
			min-width:50px;
			.file-extension {
				padding:18px 0;
				text-transform:lowercase;
				font-size:0.889rem;
				font-family:$title-font-family;
				font-weight:600;
        line-height:1;
				text-align:center;
				height: 50px;
				width: 50px;
				background-size: 50px;
			}
		}
		
		.attachment-text {
			overflow:hidden;
			
			.file-name {
				overflow:hidden;
				font-family:$title-font-family;
				font-weight:600;
			}
			.file-size {
				font-size:0.750em;
				color:$secondary;
			}
		}
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      background:transparent $ring-docx no-repeat scroll 50% 50%;
      color:$docx_color;
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background:transparent $ring-xlsx no-repeat scroll 50% 50%;;
      color:$xlsx_color;
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background:transparent $ring-pptx no-repeat scroll 50% 50%;;
      color:$pptx_color;
    }
  }
  .file-type-pdf {
    .file-extension {
      background:transparent $ring-pdf no-repeat scroll 50% 50%;;
      color:$pdf_color;
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background:transparent $ring-txt no-repeat scroll 50% 50%;;
      color:$txt_color;
;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background:transparent $ring-jpg no-repeat scroll 50% 50%;;
      color:$jpg_color;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension { 
      background:transparent $ring-zip no-repeat scroll 50% 50%;;
      color:$zip_color;
    }
  }
} 

.palette-highlight {
	.view-attachments {
		.attachment-link {
			color:$gunmental;
			
			.file-extension { 
				background-image:$ring-dark;
				color:$gunmental;
			}
			.file-size {
				color:$gunmental;
			}	
			
			&:hover {
				color:white;
				
				.file-extension { 
					background-image:$ring-white;
					color:white;
				}
				.file-size {
					color:white;
				}		
			}
		}
	}
}