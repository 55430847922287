html, body {
	color:$primary;
	font-family:$base-font-family;
	font-size:$base-font-size;
	line-height:$base-line-height;
	-webkit-font-smoothing: antialiased;
}


h1, h2, h3, h4, h5, h6 {
	color:$gunmental;
	font-family:$title-font-family;
	margin-top:0;
	text-transform:none;
	
	&.card-title {
		font-family:$base-font-family;
	}
	&.card-title-sm {
		font-family:$base-font-family;
		font-size:1em;
		margin-bottom:$vert-space/4;
		
		a {
			color:$action-default;
			
			&:hover {
				color:$action-hover;
			}
		}
	}
}
h1, h2 {
	font-weight:normal;
}
h3, h4, h5, h6 {
	font-weight:normal;
}

.l-region--sidebar-second {
	h3, h4, h5, h6 {
		font-family:$base-font-family;
	}
}
h1 {
	font-size:2.333rem;
	line-height:1.375;
	margin-bottom:$vert-space;
	
	@include media($normal) {
		font-size:2.667rem;
		line-height: 1.33333333333333;
	}
}
h2 {
	font-size:2.111rem;
	line-height:1.375;
	margin-bottom:0.5em;
	text-transform: uppercase;

	@include media($normal) {
		font-size:2.333rem;
		line-height: 1.33333333333333;
	}
}
h3 { 
	font-size:1.889rem;
	line-height:1.375;
	margin-bottom:0.5rem;
	
	@include media($normal) {
		font-size:2.111rem;
		line-height: 1.33333333333333;
	}
}

h3.block__title {
	
	font-size:1.889rem;
	
	@media screen and (min-width: 768px) {
		text-align: center;
		font-size: 2.111rem;
	}
}

h4 {
	font-size:1.556rem;
	line-height:1.375; /*1.3;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.889rem;
		line-height: 1.33333333333333;
	}
}
h5 {
	font-size:1.333rem;
	line-height:1.375; /*1.333;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.556rem;
		line-height: 1.42105263157895;
	}
}
h6 {
	font-size:1.111rem;
	line-height:1.375; /*1.333;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.333rem;
		line-height: 1.42105263157895;
	}
}

p {
	margin:0 0 $vert-space;
}
main,
.postscript-first {
	.card-body,
	.card-description {
		p, address, pre, ul, ol {
			font-outer-wrappersize: $base-font-size;
			line-height: $base-line-height;
			
			&.smaller-text {
				font-size: 0.778em;
				line-height: 1.5;
				
				p, li {
					font-size: 1em;
				}
			}
			&.larger-text {
				font-size: 1.222rem;
				line-height: 1.3;
				p, li {
					font-size: 1em;
				}
			}				
			// @include media($normal) {
			// 	font-size:1.125rem;
			// 	line-height: 1.44444444444444;
			// 
			// 	&.smaller-text {
			// 		font-size: 0.778em;
			// 		line-height: 1.5;
			// 	}
			// 	&.larger-text {
			// 		font-size: 1.222rem;
			// 		line-height: 1.3;
			// 	}				
			// }
		}
		.caption {
			display: block;
			line-height: 1.5em;
			text-align: left;

			p {
				font-size: 0.778em;
				line-height: 1.5;

				@include media($normal) {
					font-size:0.778rem;
					line-height: 1.5;
				}
			}
		}
	}
}
b, strong {
	font-weight:600;
}

a, a:link, a:active, a:visited {
	color:$action-default;
	text-decoration:none;

	&.active {
		color:$action-active;
		text-decoration:none;
	}

	&:hover,
	h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
		color:$action-hover;
		text-decoration:none;
	}
	
	&:not([href]) {
		color: inherit;
	}
}

.date,
.card-date {
	font-size:0.778rem;
	font-style:normal;
}

em, i {
	font-style: italic;
}

sup, sub {
	font-size: smaller;
	&:before {
		content: "";
		margin-left: 0.25em;
	}
}
sup { vertical-align: super; }
sub { vertical-align:sub; }
