blockquote {
  background: transparent $quote-before no-repeat 0 0 / 68px 55px;
  min-height: 60px;
  padding-left: 90px;
  
  p {
    border-left: 1px solid $action-active;
    padding-left: 15px;
  
   &.smaller-text {
     border: none;
     padding-left: 16px;
   }
  }
}

.palette-highlight {
  blockquote {
    background: transparent $quote-before-alt no-repeat 0 0 / 68px 55px;
    
    p {
      border-left: 1px solid white;
    }
  }
}
  