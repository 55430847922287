.view-timeline {
	padding: 30px;
	position: relative;
	// &:before,
	// &:after {
	// 	display:inline-block;
	// 	height: 20px;
	// 	width: 20px;
	// 	border-radius: 50%;
	// 	background-color: $timeline-bg-default-color;
	// 	content:"";
	// 	position: absolute;
	// 	top: 12px;
	// 	left: calc(50% - 10px);
	// }
	&:after {
		top: auto;
		bottom: 19px;
	}
	.simple_timeline {
		ul.remora-timeline {
			//background: $timeline-bg-default center top repeat-y; 
			background: none;
			li {
				//margin: 0 -30px;
				margin: 0 5px;
				a.card {
					.card-timeline-title {
						color:$action-default;
					}
					&:hover {
						box-shadow: none;
						.card-text *{
							color:$timeline-default-text-color;
						}
						.card-timeline-title {
							color:$action-hover;
						}
					}
				}
				.card {
					position: relative;
					overflow: visible;
					contain: layout;
					background: none;
    			box-shadow: none;
    			text-align: left;
					margin-bottom: 0;
					&:hover {
						background:none;
					}
					.card-timeline-title {
						font:$timeline-title-font;
						margin-bottom:10px;
					}
					.card-timeline-date {
						font:$timeline-date-font;
						//color:$timeline-default-date-color;
						margin-bottom:10px;
					}
					.card-text {
						width:100%;
						@media(min-width:$normal) {
							@include span-columns(8); 
						}
						@media(min-width:$wide) {
							@include span-columns(9); 
						} 
						.card-description p {
							margin-bottom: 0;
						}
					}
					.card-image {
						width:100%;
						margin-bottom: 15px;
						@media(min-width:$normal) {
							@include span-columns(4); 
							margin-bottom: 0;
						}
						@media(min-width:$wide) {
							@include span-columns(3);
						} 
					} 
					&:after {
						display:inline-block;
						height: 40px;
						width: 40px;
						border-radius: 50%;
						background-color: white;
						//border:solid 4px $timeline-bg-default-color;
						content:"";
						position: absolute;
			    	top: calc(50% - 20px);
					}
				}
				&:nth-child(odd) {
					.card {
						&:after {
							right:-25px;
						}
					}
				}
				&:nth-child(even) {
					.card {
						&:after {
							left:-25px;
						}
					}
				}
			}
		}
	}
}

// PALLETTE SPECIFIC STYLES 

// .section-row {
// 
// 	&.palette-default,
// 	&.palette-alt-1,
// 	&.palette-alt-2,
// 	&.palette-alt-3, {
// 		.view-timeline {
// 			.simple_timeline {
// 				ul.remora-timeline {
// 					background: $timeline-bg-default center top repeat-y;
// 					//Static card
// 					.card {
// 						.card-timeline-title,
// 						.card-text {
// 							color:$timeline-default-text-color;
// 						}
// 						.card-timeline-date {
// 							color:$timeline-default-date-color;
// 						}
// 					}
// 					//Linked card
// 					a.card {
// 						.card-timeline-title {
// 							color:$action-default!important;
// 						}
// 						&:hover {
// 							.card-timeline-title {
// 								color:$action-hover!important;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// 
// 	&.palette-highlight {
// 		.view-timeline {
// 			&:before,
// 			&:after {
// 				background-color: $timeline-bg-alt-color;
// 			}
// 			.simple_timeline {
// 				ul.remora-timeline {
// 					background: $timeline-bg-alt-1 center top repeat-y;
// 					//Static card
// 					.card {
// 						.card-timeline-title,
// 						.card-text {
// 							color:$timeline-alt-1-text-color;
// 						}
// 						.card-timeline-date {
// 							color:$timeline-alt-1-date-color;
// 						}
// 					}
// 					//Linked card
// 					a.card {
// 						.card-timeline-title {
// 							color:$action-default!important;
// 						}
// 						&:hover {
// 							.card-timeline-title {
// 								color:$action-hover!important;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// 
// }

.view-timeline .simple_timeline ul.remora-timeline li {
	.card {
		.card-timeline-date {
			color:$set-default-color;
		}
		&:after {
			background:white $ico-web no-repeat 50% 50% / 19px 19px;
			border:solid 2px $set-default-color;
		}
		&.set-765 {
			.card-timeline-date {
				color:$set-global-color;
			}
			&:after {
				background:white $ico-facebook no-repeat 50% 50% / 19px 19px;
				border:solid 2px $set-global-color;
			}
		}	
		&.set-766 {
			.card-timeline-date {
				color:$set-communication-color;
			}
			&:after {
				background:white $ico-twitter no-repeat 50% 50% / 19px 19px;
				border:solid 2px $set-communication-color;
			}
		}
	}
	&:nth-child(odd) {
		.card {
			.card-text {
				padding: 0 30px 0 20px;
			}
			border-right:solid 10px $set-default-color;
			&.set-765 {
				border-right:solid 10px $set-global-color;
			}	
			&.set-766 {
				border-right:solid 10px $set-communication-color;
			}
		}
	}
	&:nth-child(even) {
		.card {
			.card-text {
				padding: 0 20px 0 30px;
			}
			.card-image {
				float: right;
			}
			border-left:solid 10px $set-default-color;
			&.set-765 {
				border-left:solid 10px $set-global-color;
			}
			&.set-766 {
				border-left:solid 10px $set-communication-color;
			}
		}
	}
}