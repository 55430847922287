.i18n-en .site-title a { background:$logo-en no-repeat scroll 0 0 transparent; }

#site-header {
	background-color:$header-bg-color;
	&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
		border-bottom: 4px solid $nav-wrapper-bg-color;
		@include media(880px) {
			border-bottom: 0;
		}
	}
	
	@include media(880px) {
		border: none;
	}
	//
	// /* Change .header-outer-wrapper to
	// .header-inner-wrapper, main-nav-wrapper
	// to make the menu a band in it's own right. */
	//
	.header-inner-wrapper {
		@include outer-container($site-max-width);
		position: relative;
	}
	#main-navigation {
		@include outer-container($site-max-width);
		position: relative;
	}
	
	.site-branding {
		//margin-top:2.5rem;
		margin-left:1.25rem;
		position: relative;
		@include media(880px) {
			top:0;
		}
		.site-title {
			position:absolute;
			left:0;
			top:100px;
			
			@include media(540px) {
				top:90px;
			}
			
			@include media(880px) {
				top:20px;
			}
			
			a {
				background-size:contain;
				display:block;
				text-indent:-999em;
				overflow:hidden;
				width:200px;
				height:100px;
				
				@include media(540px) {
					width:240px;
				}
			}
		}
		.site-slogan {
			position:absolute;
			left:235px;
			top:2.5rem;
		}
	}
	
	.wrapper-region-header {
		display: flex;
		float: right;
		
		.block-search {
			height:10rem;
			@include media(880px) {
				height:auto;
			}
			
			h2 {
				display: none;
			}
			.form-item-search-block-form {
				margin-top:40px;
				margin-right:1.125rem;
				
				@media (min-width: 1150px) {
					margin-right:1.5625rem;
				}
				
				margin-bottom:30px;
				float:left;
				
				input.form-text {
					width:10rem;
					height:1.875rem;
					background-color:$header-search-field-bg-color;
					color:$header-search-field-text-color;
					box-shadow:$header-search-field-box-shadow;
					border:$header-search-field-border;
					border-radius:$header-search-field-border-radius;
					font:$header-search-field-font;
					padding:0.375rem 0.75rem;
				}
			}
			.form-actions {
				float:left;
				margin-top:0.75rem;
				input.form-submit {
					background: transparent $search no-repeat scroll 0 0;
					background-size:14px;
					width:0.875rem;
					height:0.875rem;
					text-indent:-999em;
					overflow:hidden;
					padding:0;
					position:absolute;
					right:1.25rem;
					margin-right:0.325rem;
					
					@media (min-width: 1150px) {
						margin-right:0.7625rem;
					}
					
					top:50px;
					border: none;
				}
			}
		}

		.block-menu {
			margin-top:24px;
			margin-right:0.5rem;
			li {
				display:inline-block;
				margin:0 0.75rem 0 0;
			}

			a {
				font:$header-menu-font;
				color:$header-menu-link-color;

				&:hover {
					color:$header-menu-link-hover;
				}
			}
		}
		
		.block-locale {
			clear:both;
			display:none;
		}
	}
}
