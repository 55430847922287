.card-tagline-text {
  font-size: 1.778rem;
  padding: 50px 50px;
  font: $title-font-family;
  text-align: center;
  
  p:last-child {
    margin-bottom: 0;
  }
  
  @include media ($normal) {
    width: 80%;
    margin: 0 auto;
  }
}

.card-tagline-text {
  background-color: $azureish_white;
}
.palette-alt-1,
.palette-alt-2 {
  .card-tagline-text {
    background-color: white;
  }
}
.palette-alt-3 {
  .card-tagline-text {
    background-color: $mustard;
  }
} 