button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: $button-bg;
  border: $button-border;
  border-color: $button-border-color;
  border-radius: $button-border-radius;
  color: $button-text;
  cursor: pointer;
  display: inline-block;
  font: $button-font;
  padding: 7px 10px 5px;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
	outline: none;
  letter-spacing: 1px;

  &:hover,
  &:focus {
    border-color: $button-hover-border-color;
    background-color: $button-hover-bg;
    color:  $button-hover-text;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

a.button,
.button a {
	@include button-link-default();
  border-radius: $button-border-radius;
  border: $button-border;
  border-color: $button-border-color;
  cursor: pointer;
  display: inline-block;
  font: $button-link-font;
  padding: 5px 10px;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  letter-spacing: 1px;
}

.views-exposed-widget-submit {
  overflow: hidden;
  width: 200px;
  .views-exposed-widget {
    display: inline-block;
    float: none;
  }
  .views-reset-button {
    padding-right: 0;
    
    input[type="submit"] { 
      border-color: $button-alt-border-color;
      background-color: $button-alt-bg;
      color:  $button-alt-text;
      
      &:hover,
      &:focus {
        border-color: $button-alt-hover-border-color;
        background-color: $button-alt-hover-bg;
        color:  $button-alt-hover-text;
      }
    }
  }
}