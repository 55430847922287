// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-title-font-family		: $title-font-family;
$card-title-font        	: normal 1.333rem/1.22222222222222 $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: normal 1.333rem/1 $card-title-font-family;
$card-feed-link-font			: 600 1.222rem/1 $card-title-font-family;


$card-shadow                   : $box-shadow;
$card-border                   : none;
$card-border-radius            : 3px;
$card-text-padding             : $vert-space*1.5 $horz-space*1.5 !default;
$card-text-padding-small       : $vert-space*1.5 $horz-space !default;
$card-margin-bottom            : $vert-space !default;
$card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-bg-color            : white;
$card-date-border              : none; 
$card-date-border-radius       : 3px 3px 0 0;
$card-date-text-color          : $mustard;
$card-date-font                : 1.222rem/1 $base-font-family;

$card-date-alt-bg-color            : $mustard;
$card-date-alt-text-color          : $gunmental;


// /* --- HOVER STYLES: SAME FOR ALL CARDS */
$card-hover-bg-color           : white;
$card-hover-border             : none;
$card-hover-shadow             : $box-shadow;
$card-action-hover             : $action-active-hover; //colour of card title text when you hover over it
$card-text-hover-color         : $secondary; //colour of regular card text when you hover over it
$card-date-hover-text-color    : $mustard;
$card-date-hover-bg-color      : white; // allows display dates (e.g. events) to have different hover background from the rest of the card

$card-alt-hover-bg-color           : $mustard;
$card-alt-hover-border             : none;
$card-alt-hover-shadow             : $box-shadow;
$card-alt-action-hover             : white; //colour of card title text when you hover over it
$card-alt-text-hover-color         : $primary; //colour of regular card text when you hover over it
$card-alt-date-hover-text-color    : $gunmental ;
$card-alt-date-hover-bg-color      : $mustard; // allows display dates (e.g. events) to have different hover background from the rest of the card

/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- uncomment the appropriate line in base/_cards.scss as appropriate
// ----- uncomment the appropriate line in mixins/_mixins.scss to include the _card-hidden-summary.scss mixin functinality
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : white; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : white;
$card-light-border-color       : transparent;
$card-light-title-color        : $gunmental;
$card-light-action-default     : $action-default;
$card-light-action-hover       : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-light-text-color         : $secondary;
$card-light-subtitle-color     : $secondary;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $mustard;
$card-medium-border-color      : transparent;
$card-medium-title-color       : $gunmental;
$card-medium-action-default    : $gunmental;
$card-medium-action-hover      : white; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-medium-text-color        : $primary;
$card-medium-subtitle-color    : $primary;

// /* --- CARD-DARK - dark coloured background */
// $card-dark-bg-color            : red;
// $card-dark-border-color        : transparent;
// $card-dark-title-color         : $black;
// $card-dark-action-default      : $black;
// $card-dark-action-hover        : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
// $card-dark-text-color          : $secondary;
// $card-dark-subtitle-color      : $secondary;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
// $card-highlight-bg-color       : red;
// $card-highlight-border-color   : transparent;
// $card-highlight-title-color    : $pale_grey;
// $card-highlight-action-default : $grey;
// $card-highlight-action-hover   : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
// $card-highlight-text-color     : $white;
// $card-highlight-subtitle-color : $pale_grey;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : white;
$card-more-link-default-color       : $gunmental;
$card-more-link-default-icon        : $arrow-dark-right no-repeat 100% 100% / 14px auto;
$card-more-link-default-hover-bg    : white;
$card-more-link-default-hover-color : $action-hover;
$card-more-link-default-hover-icon  : $arrow-red-right no-repeat 100% 100% / 14px auto;

$card-more-link-alt-bg              : $mustard;
$card-more-link-alt-color           : $gunmental;
$card-more-link-alt-icon            : $arrow-dark-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-hover-bg        : $mustard;
$card-more-link-alt-hover-color     : white;
$card-more-link-alt-hover-icon      : $arrow-white-right no-repeat 100% 100% / 14px auto;



// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : none; // background for the entire link
$card-feed-link-default-color       : $action-hover;
$card-feed-link-default-icon        : $btn-active-red no-repeat scroll 50% 50% / 82px 4px transparent;
$card-feed-link-default-hover-bg    : none;
$card-feed-link-default-hover-color : $action-default;
$card-feed-link-default-hover-icon  : $btn-active-green no-repeat scroll 50% 50% / 82px 4px transparent;

$card-feed-link-alt-bg              : none; // background for the entire link
$card-feed-link-alt-color           : white;
$card-feed-link-alt-icon            : $btn-active-white no-repeat scroll 50% 50% / 82px 4px transparent;
$card-feed-link-alt-hover-bg        : none;
$card-feed-link-alt-hover-color     : $action-default;
$card-feed-link-alt-hover-icon      : $btn-active-green no-repeat scroll 50% 50% / 82px 4px transparent;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : transparent;
$button-link-default-border-color       : $action-active;
$button-link-default-color              : $action-active;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : transparent;
$button-link-default-hover-border-color : $action-default;
$button-link-default-hover-color        : $action-default;
$button-link-default-hover-icon         : none;

$button-link-alt-bg                     : transparent;
$button-link-alt-border-color           : white;
$button-link-alt-color                  : white;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : transparent;
$button-link-alt-hover-border-color     : $action-default;
$button-link-alt-hover-color            : $action-default;
$button-link-alt-hover-icon             : none;