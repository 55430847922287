// /* DEFAULT CARD LAYOUT	*/
.card {
	padding: 3px;
	transition: $card-transition;
	margin-bottom:$card-margin-bottom;
	outline: none;

	&.slick-slide {
		margin-top: 10px;
	}
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
		
		h3 {
			font:$card-title-font;
		}
	} 

	h3 {
		display:block;
		font:$card-title-font;
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
		}
	}	
	.card-text {
		padding: $card-text-padding;
		margin: 0;

		a { word-wrap: break-word; }
	}
	
	&.card-with-image {
		.card-image {
			position: relative;
			z-index: 3;
		}
		.card-text {
			margin: -30px auto 0;
	    width: calc(100% - #{$horz-space}*2);
			position: relative;
			z-index: 4;
		}
		.card-display-date {
			position: relative;
			padding-bottom: 6px;
			margin: auto 20px;
		}
	}
}

// TAGGED CONTENT
.card-title-et {
	h3 {
		font:$card-title-font;
	}
}

@include media($normal) {
	.row-max-6 {
		.card {
			.card-text {
				padding: $card-text-padding-small;
			}
		}
	}
}

.card-more-link .card,
.card .card-text {
	border:$card-border;
	border-radius: $card-border-radius;
	box-shadow:$card-shadow;
}

.card-top-date {
	display: flex;
	justify-content: center;
}

.card-top-wrapper {
	position: relative;
	.card-top-date {
		.card-display-date {
			background: $card-date-bg-color;
			border-bottom:$card-date-border;
			border-radius:$card-date-border-radius;
			color:$card-date-text-color;
			font:$card-date-font;
			padding:6px 12px 0;
			box-shadow:$top-date-shadow;
		}
	}
}

.card-with-image {
	.card-top-wrapper {
		.card-top-date {
			position: absolute;
	    bottom: 0;
	    z-index: 6;
	    width: 100%;
		}
		
		& + .card-text {
			border-radius: 0 0 3px 3px;
			width: 100%;
			margin-top: 0;
		}
	}
}

.palette-alt-3 {
	.card-display-date {
		background: $card-date-alt-bg-color;
		color:$card-date-alt-text-color;
	}
}

// /* MORE LINK */
.section-row:not(.view-type-slider), .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
	{
	.card-more-link {
		display:table !important;
		margin-left:2.35765%;
		text-align:center;
		padding: 3px;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:16px;
				margin:0 0 -2px 10px;
				width:14px;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}

// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	overflow: hidden;
	a {
		&:after {
			content: "";
	    display: block;
	    width: 100%;
	    height: 4px;
	    margin-top: 3px;
		}
	}
}



// /* INNER CARD ELEMENTS */
.tweet .card-text {
	display:block;
	font:normal 1.125em/1.22222222222222 $base-font-family;
}

.card-logo {
	background:#FFFFFF;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}

.card-tag,
.card-tags {
	display: inline-block;
	font-size: 0.875em;
	font-weight: 600;

	ul {
		display: inline-block;
		li {
			float: left;
			margin-right: 10px;
			&:after {
				content:"|";
				display: inline-block;
				float: right;
				margin-left: 10px;
			}
			&:last-child {
				&:after {
					content:"";
				}
			}					
		}
	}
}

.card-tag {
  margin-top: 30px;
  font-style: italic;
	font-weight: normal;
}

.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-citation {
	font-style:italic;
}
.card-date,
.card-location {
	font-size:0.875em;
	line-height:1.57142857142857;
}
.card-type {
	font-size:0.875rem;
	line-height:1.57142857142857;
}
.card-label {
	font-weight:bold;
}
.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-date-display-override {
	font-size: 0.875em;
}
.card-resource-type,
.card-content-type {
	display:inline-block;
	font-weight: 400;
	font-size: 0.875em;
}
.card-email {
	font-weight: 600;
}

.card-job-title {
	font-size:1em;
	line-height:1.375;
}

.card-footer {
	display: block;
	line-height: 1.125em;
}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}
//Display box-link card-summary on hover 
//@include card-hidden-summary;


// ROW-MAX-1 
@media (min-width: 540px) {
	.page-node:not(.sidebar-second) main,
	.postscript-first {
		.row-max-1 {
			article:not(.node-teaser-image),
			.custom-link-flexi {
				.card-with-image {
					overflow: hidden;
					display: flex;
					flex-wrap: wrap;
					
					.card-top-wrapper	{	
						@include span-columns(5);
						margin-right: 0;
						position: initial;
						
						.card-top-date {
							width: calc(100% + 20px - 40.29137%);
							position: absolute;
							right: 10px;
							z-index: 5;
							bottom: auto;
							top: 5px;
						}
							
						.card-image,
						.card-photo,
						.card-logo {							
							//@include span-columns(5); //3
							width: 100%;
						}
					}
					
					.card-text {
						width: calc(100% + 20px - 40.29137%);
						margin: 30px 0 0 -30px;
						display: inline-block;
						height: 100% !important;
						border-radius: $card-border-radius;
					}
				}
			}
		}
	}
}
//row-max-1 with sidebars
@media (min-width: 540px) and (max-width:$narrow) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 {
				article:not(.node-teaser-image),
				.custom-link-flexi {
					.card-with-image {
						overflow: hidden;
						display: flex;
						flex-wrap: wrap;
						
						.card-top-wrapper	{	
							@include span-columns(5);
							margin-right: 0;
							position: initial;
							
							.card-top-date {
								width: calc(100% + 20px - 40.29137%);
								position: absolute;
								right: 10px;
								z-index: 5;
								bottom: auto;
								top: 5px;
							}
								
							.card-image,
							.card-photo,
							.card-logo {							
								//@include span-columns(5); //3
								width: 100%;
							}
						}
						
						.card-text {
							width: calc(100% + 20px - 40.29137%);
							margin: 30px 0 0 -30px;
							display: inline-block;
							height: 100% !important;
							border-radius: $card-border-radius;
						}
					}
				}
			}
		}
	}
}
@media (min-width:$narrow) and (max-width:$normal) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 {
				article:not(.node-teaser-image),
				.custom-link-flexi {
					.card-with-image {
						overflow: hidden;

						.card-image,
						.card-photo,
						.card-logo {							
							float: none;
							position: relative;
						}
					}
				}
			}
		}
	}
}
@media (min-width:$normal) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 {
				article:not(.node-teaser-image),
				.custom-link-flexi {
					.card-with-image {
						overflow: hidden;
						display: flex;
						flex-wrap: wrap;
						
						.card-top-wrapper	{	
							@include span-columns(5);
							margin-right: 0;
							position: initial;
							
							.card-top-date {
								width: calc(100% + 20px - 40.29137%);
								position: absolute;
								right: 10px;
								z-index: 5;
								bottom: auto;
								top: 5px;
							}
								
							.card-image,
							.card-photo,
							.card-logo {							
								//@include span-columns(5); //3
								width: 100%;
							}
						}
						
						.card-text {
							width: calc(100% + 20px - 40.29137%);
							margin: 30px 0 0 -30px;
							display: inline-block;
							height: 100% !important;
							border-radius: $card-border-radius;
						}
					}
				}
			}
		}
	}
}

//ROW-MAX-2
//If row-max-2 is selected and the page is more than 960px in width and DONT have sidebar second, show the image on the left
@media (min-width:$normal) {	
	.page-node:not(.sidebar-second) main,
	.postscript-first {
		.section-row:not(.view-type-slider) {
			&.row-max-2 .views-row {
				.card-with-image {
					overflow: hidden;
			    display: flex;
			    flex-wrap: wrap;
					
					// .card-top-date {
					// 	width: calc(100% + 20px - 48.82117%);
				  //   position: absolute;
				  //   right: 0;
					// 	z-index: 5;
					// }
					
					.card-top-wrapper	{	
						@include span-columns(6);
						margin-right: 0;
						position: initial;
						
						.card-top-date {
							width: calc(100% + 20px - 48.82117%);
							position: absolute;
							right: 10px;
							z-index: 5;
							bottom: auto;
							top: 5px;
						}
							
						.card-image,
						.card-photo,
						.card-logo {							
							//@include span-columns(5); //3
							width: 100%;
						}
					}
					
					.card-text {
				    width: calc(100% + 20px - 48.82117%);
				    margin: 30px 0 0 -30px;
				    display: inline-block;
						height: 100% !important;
						border-radius: $card-border-radius;
					}
				}
			}
		}
	}
}


.section-row:not(.view-type-slider) {
	.card-image,
	.card-photo,
	.card-logo {
    float: none;
	}
}