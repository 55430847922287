// /* MAIN MENU */ //

$nav-base-font        	 	: 1.444rem/1.3 $title-font-family;
$nav-base-font-smaller 		: 1.25rem/1.375 $title-font-family;
$nav-sub-base-font        : 1rem/1.3 $base-font-family;

$nav-wrapper-bg-color					: $azureish_white;
$nav-base-bg-color        		: white; 
$nav-base-text-color     		 	: $gunmental;
$nav-base-padding        			: 0.625rem 1.5625rem;
$nav-base-hover-padding   		: 0.625rem 1.5625rem;

$nav-sub-base-padding					: 0.667rem 1.944rem 0.500rem 0.667rem;
$nav-sub-base-hover-padding		: 0.667rem 1.944rem 0.500rem 0.667rem;

$nav-base-padding-smaller 				: 0.375rem 1.125rem; //for use at awkward breakpoints so the menu still fits on one line
$nav-base-hover-padding-smaller 	: 0.375rem 1.125rem;

$nav-base-border-color        : transparent;

$nav-active-line	  	        : radial-gradient($rusty_red,transparent 75%); //linear-gradient(to right, transparent,$rusty_red,transparent); //$menu-active-red no-repeat scroll 50% 50% / 123px 3px transparent;
$nav-active-hover-line	  	  : radial-gradient($dark_cyan,transparent 75%); //linear-gradient(to right, transparent,$dark_cyan,transparent); //$menu-active-green no-repeat scroll 50% 50% / 123px 3px transparent;

$nav-sub-active-line	  	        : radial-gradient($rusty_red,transparent 75%); //$menu-sub-active-red no-repeat scroll 50% 50% / 3px 20px transparent;
$nav-sub-active-hover-line	  	  : radial-gradient($dark_cyan,transparent 75%); //$menu-sub-active-green no-repeat scroll 50% 50% / 3px 20px transparent;

$nav-active-bg-color      		: transparent;
$nav-active-text-color    		: $rusty_red;
$nav-active-border-radius 		: 0; 
$nav-active-border-color	  	: $nav-active-line; // $rusty_red;

$nav-hover-bg-color       		: transparent;
$nav-hover-text-color     		: $dark_cyan;
$nav-hover-border	  	    		: none;
$nav-hover-border-color	  	  : $nav-active-hover-line; //$dark_cyan;

$nav-highlight-bg-color   		: $alice_blue;
$nav-highlight-text-color 		: $gunmental; 
$nav-highlight-border	    		: none;
$nav-highlight-border-color 	: transparent;;

$nav-sub-bg-color         		: white;
$nav-sub-text-color       		: $primary;
$nav-sub-border-color         : transparent;

$nav-sub-highlight-bg-color   		: $azureish_white;

$nav-sub-hover-bg-color   		: $alice_blue;
$nav-sub-hover-text-color   	: $dark_cyan;
$nav-sub-hover-border-color   : transparent;

$nav-sub-active-text-color    : $rusty_red;
$nav-sub-active-bg-color      : transparent;
$nav-sub-active-border-color  : $nav-sub-active-line; // $rusty_red;

$nav-sub-active-hover-text-color     : $dark_cyan;
$nav-sub-active-hover-border-color   : $nav-sub-active-hover-line; // $dark_cyan;

$nav-sub-current-expanded-text-color     : $rusty_red;
$nav-sub-current-expanded-border-color   : transparent;

$nav-sub-box-shadow      			: $box-shadow;
$nav-sub-border          			: none;
$nav-sub-border-radius  		  : none;

// /* MOBILE */ //

$nav-mob-text-color      						: $gunmental;
$nav-mob-active-text-color      		: $rusty_red;
$nav-mob-active-bg-color      			: inherit;
$nav-mob-active-border-color	  	  : $nav-sub-active-line; //$rusty_red;
$nav-mob-active-hover-border-color	: $nav-sub-active-hover-line; //$dark_cyan;

$nav-mob-current-text-color      		: $rusty_red;
$nav-mob-current-bg-color      			: inherit;

$nav-mob-hover-bg-color    					: $azureish_white;

$nav-mob-1st-bg-color     					: white;
$nav-mob-1st-text-color      				: $gunmental;
$nav-mob-1st-hover-bg-color         : $azureish_white;
$nav-mob-1st-hover-text-color       : $dark_cyan;
$nav-mob-1st-highlight-bg-color     : $alice_blue;
$nav-mob-1st-highlight-text-color   : $dark_cyan;
$nav-mob-1st-border-color     			: $maximum_blue_green;
$nav-mob-1st-highlight-border-color : transparent;

$nav-mob-2nd-bg-color     					: $alice_blue;
$nav-mob-2nd-bg-color     					: $alice_blue;
$nav-mob-2nd-highlight-bg-color     : $azureish_white;
$nav-mob-2nd-highlight-text-color   : $dark_cyan;
$nav-mob-2nd-line-color     				: white;
$nav-mob-2nd-border-color     			: white;
$nav-mob-2nd-text-color      				: $gunmental;

$nav-mob-3rd-bg-color     					: $azureish_white;
$nav-mob-3rd-highlight-bg-color     : $azureish_white;
$nav-mob-3rd-highlight-text-color   : $azureish_white;
$nav-mob-3rd-line-color     				: white;
$nav-mob-3rd-border-color     			: white;
$nav-mob-3rd-text-color      				: $gunmental;

$nav-mob-border											: 1px solid $azureish_white;
$nav-mob-expanded-border						: 1px solid $azureish_white;
$nav-mob-top-border						      : 4px solid $maximum_blue_green;
$nav-mob-border-hover								: none;
$nav-mob-shadow											: $box-shadow;



/* MOBILE MENU TRIGGER */
$nav-button-bg-color						: $azureish_white;
$nav-button-hover-bg-color			: $maximum_blue_green;
$nav-button-height      				: 66px;
$nav-button-width      					: 76px;
$nav-button-bar-bg-color      	: $davys_grey;
$nav-button-bar-hover-bg-color  : white;
$nav-button-bar-height      		: 6px;
$nav-button-bar-width      			: 46px;
$nav-button-bar-border-radius 	: 8px;
