// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: transparent; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: transparent $pager-white-previous no-repeat scroll 50% 50%;
$slick-default-prev-hover-bg      				: transparent $pager-green-previous no-repeat scroll 50% 50%;
$slick-default-next-bg            				: transparent $pager-white-next no-repeat scroll 50% 50%;
$slick-default-next-hover-bg      				: transparent $pager-green-next no-repeat scroll 50% 50%;
$slick-default-dot-border         				: none;
$slick-default-dot-hover-border    				: none;
$slick-default-dot-bg-color   				    : $aurometalsaurus;
$slick-default-dot-hover-bg-color   	    : $mustard;
$slick-default-dot-active-bg-color 				: $action-active;
$slick-default-dot-active-hover-bg-color 	: $mustard;


$slick-alt-1-nav-bg                				: transparent; // used on slide and galler views
$slick-alt-1-controls-bg           				: none; // used on twitter views etc
$slick-alt-1-prev-bg               				: transparent $pager-green-previous no-repeat scroll 50% 50%;
$slick-alt-1-prev-hover-bg         				: transparent $pager-yellow-previous no-repeat scroll 50% 50%;
$slick-alt-1-next-bg               				: transparent $pager-green-next no-repeat scroll 50% 50%;
$slick-alt-1-next-hover-bg         				: transparent $pager-yellow-next no-repeat scroll 50% 50%;
$slick-alt-1-dot-default-border    				: none;
$slick-alt-1-dot-hover-border      				: none;
$slick-alt-1-dot-bg-color   				      : $aurometalsaurus;
$slick-alt-1-dot-hover-bg-color   	      : $mustard;
$slick-alt-1-dot-active-bg-color 				  : $action-active;
$slick-alt-1-dot-active-hover-bg-color 	  : $mustard;


$slick-alt-2-nav-bg                 			: transparent; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: transparent $pager-white-previous no-repeat scroll 50% 50%;
$slick-alt-2-prev-hover-bg         				: transparent $pager-yellow-previous no-repeat scroll 50% 50%;
$slick-alt-2-next-bg               				: transparent $pager-white-next no-repeat scroll 50% 50%;
$slick-alt-2-next-hover-bg         				: transparent $pager-yellow-next no-repeat scroll 50% 50%;
$slick-alt-2-dot-default-border    				: none;
$slick-alt-2-dot-hover-border      				: none;
$slick-alt-2-dot-bg-color   				      : white;
$slick-alt-2-dot-hover-bg-color   	      : $mustard;
$slick-alt-2-dot-active-bg-color 				  : $davys_grey;
$slick-alt-2-dot-active-hover-bg-color 	  : $mustard;


// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : rgba(43, 45, 66, 0.9) ;
$slide-text-color          : white;
$slide-text-font           : 2.111rem/1.2 $title-font-family;
$slide-text-font-small     : 1.333rem/1.2 $title-font-family;