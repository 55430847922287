$timeline-title-font          : normal 1.125rem/1.22222222222222 $card-title-font-family;
$timeline-date-font           : bold 1rem/1.22222222222222 $card-title-font-family;

$timeline-bg-default-color    : $dark_cyan;
$timeline-default-text-color  : $primary;

$timeline-bg-alt-color        : white;
$timeline-alt-1-text-color    : white;

// Styles for Sets

$set-default-color            : #54748E;
$set-global-color             : #9F86C0;
$set-communication-color      : #5E548E;