.ajax-progress ,ajax-progress-throbber, .flag-throbber {
	display:none;
}
body {
	.view {
		.view-filters {
			opacity:1;
			transition:opacity 0.25s ease-in-out;
		}
		.view-content {
			opacity:1;
			transition:opacity 0.5s ease-in-out;
		}
	}
	&.ajax-processing {
		.view {
			.view-filters {
				opacity:0.5;
			}
			.view-content {
				opacity:0;
			}
			* {
				cursor:wait;
			}
		}
	}
}


.views-exposed-form {
	.views-exposed-widgets {
		background-color: $exposed-form-alt-bg;
		padding: $vert-space*1.5 $horz-space*1.5;
		
		#{$all-text-inputs},
		select[multiple=multiple],
		textarea,
		select {
			background-color: $exposed-form-alt-field-bg;
			outline: none;
			
			font-size: 0.778rem;
			padding: 7px 15px 6px;
		}
		
		label {
			color: $gunmental;
			font: $label-font;
		}
		
		select {
			padding: 5px 40px 4px 15px;
		}
		
		.form-submit {
	    margin-top: 1.444rem;
		}
	}
}
.palette-default,
.palette-highlight {
	.views-exposed-form {
		.views-exposed-widgets {
			background-color: $exposed-form-bg;
			
			#{$all-text-inputs},
			select[multiple=multiple],
			textarea,
			select {
				background-color: $exposed-form-field-bg;
			}
		}
	}
}


// DISPLAY YEAR FILTER AND HIDE DEFAULT FROM-TO FILTERS
// #views-exposed-form-publications-all,
// #views-exposed-form-resources-all,
// #views-exposed-form-resources-publications-all {
//   #edit-from-wrapper,
//   #edit-to-wrapper {
//     display: none !important;;
//   }
//   #edit-year-wrapper {
//     display: block !important;
//   }
// }